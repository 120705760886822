import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private excludedRoutes = ["/auth/sign-in", "/auth/refresh-token"]; // Rutas que no deben incluir el token

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accesoActivo = JSON.parse(localStorage.getItem("accesoActivo"));

        // Si la ruta está excluida, continúa sin modificar la solicitud
        if (this.isExcludedRoute(req.url)) {
            return next.handle(req);
        }

        // Si hay un token activo, agrégalo al encabezado
        return accesoActivo
            ? next.handle(
                  req.clone({
                      headers: req.headers.set(
                          "Authorization",
                          `Bearer ${accesoActivo.token}`
                      ),
                  })
              )
            : next.handle(req);
    }

    private isExcludedRoute(url: string): boolean {
        return this.excludedRoutes.some((route) => url.includes(route));
    }
}
